import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import favicon from '../assets/images/favicon.ico'

function Seo({ data }) {

  // console.log(data);

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en-US',
      }}
      title={data.title ? data.title : ''}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: data.metaDesc ? data.metaDesc : '',
        },
        {
          property: `og:title`,
          content: data.opengraphTitle ? data.opengraphTitle : '',
        },
        {
          property: `og:description`,
          content: data.metaDesc ? data.metaDesc : '',
        },
        {
          property: `og:type`,
          content: data.opengraphType ? data.opengraphType : '',
        },
        {
          name: `twitter:card`,
          content: data.twitterDescription ? data.twitterDescription : '',
        },
        {
          name: `twitter:creator`,
          content: data.opengraphAuthor ? data.opengraphAuthor : '',
        },
        {
          name: `twitter:title`,
          content: data.title ? data.title : '',
        },
        {
          name: `twitter:description`,
          content: data.metaDesc ? data.metaDesc : '',
        },
        {
          name: `keywords`,
          content: data.metaKeywords ? data.metaKeywords : '',
        },
      ]}
    >
      <link rel="icon" href={favicon} />
    </Helmet>
  )

}

const detailsQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
    wp {
      generalSettings {
        title
        description
      }
    }
  }
`

export default Seo
